.components-layout {
  position: relative;
  width: 100%;
  min-width: 450px;
  height: 100%;
background-color: #fff;
}
.semi-layout-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
}
.semi-input-wrapper {
  background-color: rgba(var(--semi-blue-0), 0.6);
  &:active,
  &:hover {
    background-color: rgba(var(--semi-blue-0), 0.6);
  }
}
.title {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #fff;
  
  img{
	  width: 80%;
  }
}
.link {
  display: block;
}
.login-btn{
  width: 100%;
  display: flex;
  justify-content: center;
}