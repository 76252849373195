.wrap {
  padding: 20px;
}

.dh-main{
	padding-left: 12%;
	padding-bottom: 1%;
}
.com-label{
	width: 100%;
	display: flex;
	justify-content: space-between;
}