.layout {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.icon {
  width: auto;
  max-width: 25px;
  height: 20px;
}
.container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 60px);
  color: var(--semi-color-text-0);
  background-color: var(--semi-color-nav-bg);
  .sider {
    height: 100%;
  }
  .navigation {
    max-width: 220px;
    height: 100%;
  }
  .breadcrumb {
    padding: 12px;
  }
  .right-layout {
    position: relative;
    overflow-y: auto;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 20px;
    .wrap-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;
      color: #1c1f23;
    }
  }
}
.signing-layout {
  position: absolute;
  bottom: 64px;
  left: 16px;
}
.spin-wrap {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.form-layout {
  padding: 0 40px;
}
