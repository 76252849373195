html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
menu,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  border: 0;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}
menu,
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
a {
  text-decoration: none;
  color: inherit;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
input,
select {
  outline: none;

  appearance: none;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
img {
  -webkit-touch-callout: none;
}
/* 滚动条 */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #999;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}
#root,
html,
body {
  position: relative;
  width: 100%;
  height: 100%;
}
* {
  outline: none;

  -webkit-tap-highlight-color: transparent;
}
/* 全局样式 */
.semi-navigation-header {
  cursor: pointer;
}
.fill-width {
  width: 100%;
}
.semi-table-wrapper {
  margin-top: 20px !important;
}
.search-layout {
  width: 100%;
}
.search-layout .semi-col {
  margin-bottom: 16px;
}
.form-layout {
  padding: 0 100px;
}
.bf-content {
  font-size: 14px !important;
  height: auto !important;
  min-height: 100px !important;
  max-height: 500px !important;
}
.semi-navigation-item-selected {
  background-color: #E6F5FE !important;
  color: #73ABF8 !important;
  border-right: 4px solid #3885F6;

}

.semi-navigation-item {
  border-radius: 0 !important;
}
$main-color:#1890ff;
$font-color:rgba(0,0,0,.85);
body {
  --semi-color-primary: #1890ff !important;
}
.com-btn{
  text-shadow: 0 -1px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
  line-height: 1.5715 !important;
  box-shadow: 0 2px 0 rgba(0,0,0,.015);
  height: 32px !important;
  padding: 4px 15px !important;
  font-size: 14px !important;
  border-radius: 2px !important;
  font-weight: 400 !important;
  margin: 0 5px;
  display: inline-block !important;
  width: auto !important;
}
.search-btn{
  @extend .com-btn;
  color: #fff !important;
    background: $main-color !important;
    border: 1px solid $main-color !important;
 
}
.reset-btn{
  @extend .com-btn;
  color:$font-color  !important;
  border: 1px solid #d9d9d9 !important;
  background: #fff !important;

}

//覆盖单选组样式
.semi-button-group>.semi-button-tertiary{
background-color:#F1EFF2 !important ;
color:#666!important;
width: 72px !important;
}
.semi-button-group-line{
  display: none !important;
}
.semi-button-group>.semi-button-primary{
background-color: white !important;
color: #333 !important;
}
.semi-button-group {
  display: flex;
  flex-wrap: wrap;
  background-color: #f1eff2;
  padding: 1px;
}
//覆盖输入框
.semi-input-wrapper,.semi-select{
  background-color: transparent !important;
  border: 1px solid #d9d9d9 !important;
}
.semi-input-wrapper-focus{
  border: 1px solid $main-color !important;
}