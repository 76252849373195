.wrap {
  padding: 20px;
}

.dh-main{
	.dh-title{
		font-weight: 600;
	}
}
.goods-type-data{
	display: flex;
	width: 100%;
	
	.echart-data{
		width: 30%;
	}
	.echart-data-table{
		width: 70%;
	}
}
.error-box{
	text-align: center;
	.error-tit{
	color: red;
}
}
