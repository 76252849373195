.wrap {
  padding: 20px;
}

.dh-main{
	.dh-title{
		font-weight: 600;
	}
}
.goods-type-data{
	display: flex;
	width: 100%;
	
	.echart-data{
		width: 40%;
		margin-top: 3%;
	}
	.echart-data-table{
		width: 60%;
	}
}
.tit-box{
	display: flex;
	justify-content: space-between;
	align-items: center,
}